.header {
    display: flex;
    flex-direction: column;
    padding-left: 5rem;
    height: 20rem;
    justify-content: center;
    background-color: #2a2a2a;
    margin: 2rem;
    border-radius: 1.5rem;
}

.h1 {
    font-size: 5rem;
}

.h2 {
    font-size: 1.2rem;
    font-style: italic;
    text-transform: uppercase;
}