.parent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}


.loader {
    border: 1.6rem solid #2a2a2a;
    border-top: 1.6rem solid white;
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    animation: spin 0.5s linear infinite;
    }
    
    @keyframes spin {
    0%  { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    }
