.div {
    margin-left: 20rem;
    transition: margin-left 0.2s ease;
}

.nav, .hidden {
    height: 100dvh;
    position: fixed;
    left: 0;
    top: 0;
    width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
    padding-top: 2rem;
    background-color: #121212;

    transition: transform 0.2s ease, background-color 0.2s ease;
}

.toggle, .toggleSelected {
    display: none;
    width: 5rem;
    height: 5rem;
    margin: 2rem;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    background: #2a2a2a;
    color: white;
    transition: border-radius 0.2s ease;
}

.toggle {
    border-radius: 1.5rem 0 1.5rem 0;
    border: none;
    border-right: solid 2px #121212;
    border-bottom: solid 2px #121212;
}

.toggleSelected {
    border-radius: 1.5rem;
    border: none;
}

.link {
    display: flex;
    height: 5rem;
    align-items: center;
    margin: 0 2rem;
    background: #2a2a2a;
    border: none;
    border-radius: 1.5rem;
    color: white;
    text-decoration: none;
    justify-content: center;
    cursor: pointer;
}

.title {
    padding-left: 2rem;
    height: 5rem;
    display: flex;
    align-items: center;
}

.account {
    margin-top: auto;
    margin-bottom: 2rem;
    flex-direction: column;
    height: auto;
    min-height: 5rem;
    padding: 1rem;
}

.accountPhoto {
    height: 5rem;
    width: 5rem;
    margin-bottom: 1rem;
    border-radius: 100%;
    object-fit: cover;
  }

/* @media screen and (max-width:700px) {
    .div {
        margin-left: 0rem;
    }
    
    .nav {
        padding: 0;
        bottom: 0;
        top: auto;
        width: 100vw;
        height: 9.5rem;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        justify-content: space-between;
        padding: 1rem;
        background-color: #121212;
    }

    .loginButtons {
        flex: 1;
        height: 100%;
        gap: 1rem;
    }

    .link {
        flex: 1;
        height: 7.5rem;
        margin: 0;
    }

    .title {
        display: none;
    }
} */

@media screen and (max-width:700px) {
    .div {
        margin-left: 0;
    }

    .hidden {
        transform: translate(-100%,0);
    }

    .toggle, .toggleSelected {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .title {
        align-self: flex-end;
        padding-right: 2rem;
    }
}