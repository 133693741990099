* {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  background: #121212;
  color: white;
}

.accountPage {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.accountPage button, .accountPage a:not(.forgotPass) {
  display: flex;
  height: 5rem;
  width: 30rem;
  align-items: center;
  background: #2a2a2a;
  border: none;
  border-radius: 1.5rem;
  color: white;
  text-decoration: none;
  justify-content: center;
  cursor: pointer;
}

.accountPage form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
  align-items: center;
}

.profilePicLabel {
  display: flex;
  justify-content: center;
  position: relative;
}

.profilePicLabel p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-size: 3rem;
  text-align: center;
}

.profilePicLabel img {
  border: solid 5px #2a2a2a;
  z-index: 1;
}

.profilePicLabel img:hover {
  opacity: 0.5;
}

.accountPage form div:not(.passwordConfirm) {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.passwordConfirm {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
}

.accountPage input {
  width: 30rem;
  height: 5rem;
  background: #2a2a2a;
  border: none;
  border-radius: 1.5rem;
  color: white;
  font-size: 2rem;
  padding: 1rem;
}

.accountPage form button {
  margin: 0;
}

.accountPage .accountDelete {
  color: red;
}

.accountPage img {
  width: 15rem;
  height: 15rem;
  border-radius: 100%;
  object-fit: cover;
}

.accountPage h2 {
  margin-bottom: -1rem;
  font-size: 4rem;
}

.accountPage h3 {
  margin-bottom: 5rem;
  font-size: 1.6rem;
}

.forgotPass {
  color: white;
  text-align: right;
}

.backAndReg {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.accountDiv {
  background: #191919;
  gap: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem;
  padding: 2rem;
}

.errorPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
}

.errorPage a {
  background-color: #2a2a2a;
  color: white;
  text-decoration: none;
  font-size: 2rem;
  padding: 2rem 5rem;
  border-radius: 1.5rem;
}